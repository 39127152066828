.toggle {
    display: grid;
    column-gap: var(--CademyUIRichtextEditor-spacing-xSmall);
    grid-template-columns: auto 1fr;
    align-items: center;
    .toggleButton {
        display: grid;
        align-items: center;
        font: var(--CademyUIRichtextEditor-typeset-labelSmallFont);
        background: var(--CademyUIRichtextEditor-color-body);
        color: var(--CademyUIRichtextEditor-color-onBody);
        border: none;
        cursor: pointer;
        border-radius: var(--CademyUIRichtextEditor-corners-rounded);
        padding: var(--CademyUIRichtextEditor-spacing-xxSmall);
        &:hover {
            background: color-mix(
                in srgb,
                var(--CademyUIRichtextEditor-color-surface1),
                var(--CademyUIRichtextEditor-color-onSurface1)
                    var(--CademyUIRichtextEditor-stateLayerOpacity-hover)
            );
        }
        &:active {
            background: color-mix(
                in srgb,
                var(--CademyUIRichtextEditor-color-surface1),
                var(--CademyUIRichtextEditor-color-onSurface1)
                    var(--CademyUIRichtextEditor-stateLayerOpacity-active)
            );
        }
        svg {
            width: 1em;
            aspect-ratio: 1/1;
            fill: var(--CademyUIRichtextEditor-color-onBody);
        }
    }
    .toggleContents {
        display: none;
    }

    &[data-open] {
        > .toggleContents {
            display: block;
        }
    }
}
