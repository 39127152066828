.amounts_container {
    display: flex;
    align-items: center;

    flex-direction: row;
    width: 100%;
    gap: 10px;

    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
    }
}
.amount_number_input_selected {
    border: none;
    box-shadow: inset 0 0 0 1px var(--blue);
    background-color: #f3f9ff;
}

.amount_number_input {
    padding: 12px;
    border-radius: 4px;
    border: none;
    outline: none;
    box-shadow:
        inset 0 0 0 1px rgba(67, 90, 111, 0.3),
        inset 0 1px 2px rgba(67, 90, 111, 0.14) !important;
    width: 100%;
    max-width: 200px;

    &:focus {
        border: none;
        box-shadow: inset 0 0 0 1px var(--blue);
    }
}
