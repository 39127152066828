.codeContent {
    display: grid;
    grid-template-columns: 1fr;
    code {
        overflow-x: scroll;
        border-radius: 0.375rem;
        background-color: var(--CademyUIRichtextEditor-color-codeBlock);
        padding: 1.5rem;
        font-family: monospace;
        font-size: 0.875rem;
        line-height: 1.5;
        tab-size: 2;

        color: var(--CademyUIRichtextEditor-color-onCodeBlock);
    }
}
