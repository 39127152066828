.inputContainer {
    width: 100%;
    background: var(--CademyUIRichtextEditor-color-surface1);
    border: 1px solid var(--CademyUIRichtextEditor-color-border);
    color: var(--CademyUIRichtextEditor-color-onSurface1);
    padding: var(--CademyUIRichtextEditor-spacing-medium);
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    display: flex;
    flex-direction: column;
    gap: var(--CademyUIRichtextEditor-spacing-xSmall);
    label {
        font: var(--CademyUIRichtextEditor-typeset-labelMediumFont);
    }
    textarea {
        height: 175px;
        resize: none;
        border: 1px solid var(--CademyUIRichtextEditor-color-border);
        border-radius: var(--CademyUIRichtextEditor-corners-rounded);
        padding: var(--CademyUIRichtextEditor-spacing-xSmall);
    }
    button {
        --_background: var(--CademyUIRichtextEditor-color-primary);
        --_color: var(--CademyUIRichtextEditor-color-onPrimary);
        cursor: pointer;
        width: fit-content;
        border: none;
        background: var(--_background);
        border-radius: var(--CademyUIRichtextEditor-corners-rounded);
        color: var(--_color);
        padding: var(--CademyUIRichtextEditor-spacing-xSmall);

        &:hover {
            background: color-mix(
                in srgb,
                var(--_background),
                var(--_color) var(--CademyUIRichtextEditor-stateLayerOpacity-hover)
            );
        }
        &:active {
            background: color-mix(
                in srgb,
                var(--_background),
                var(--_color) var(--CademyUIRichtextEditor-stateLayerOpacity-active)
            );
        }
        &:disabled {
            opacity: 0.8;
            cursor: not-allowed;
        }

        &[data-embed-provider='YouTube'] {
            --_background: red;
            --_color: white;
        }
        &[data-embed-provider='Canva'] {
            --_background: #8b3dff;
            --_color: white;
        }
        &[data-embed-provider='Wistia'] {
            --_background: #2949e5;
            --_color: white;
        }
    }
}
