.container {
    container-type: inline-size;

    .description {
        padding-inline-start: 0;
    }

    .fieldContainer {
        display: grid;
        gap: var(--margin-small);
        grid-template-areas:
            'street'
            'city'
            'postcode'
            'country';

        .streetInput {
            grid-area: street;
        }
        .cityInput {
            grid-area: city;
        }
        .postcodeInput {
            grid-area: postcode;
        }
        .countryInput {
            grid-area: country;
        }
    }

    @container (min-width: 450px) {
        .fieldContainer {
            grid-template-areas:
                'street street'
                'city city'
                'postcode country';
        }
    }

    @container (min-width: 700px) {
        .fieldContainer {
            grid-template-areas:
                'street street street'
                'city postcode country';
        }
    }
}
