.block {
    --_anchorWidth: 3px;
    &[data-drop-anchor='top'] {
        box-shadow: inset 0px var(--_anchorWidth) var(--CademyUIRichtextEditor-color-border);
    }
    &[data-drop-anchor='bottom'] {
        box-shadow: 0px var(--_anchorWidth) var(--CademyUIRichtextEditor-color-border);
    }
    &[data-dragging] {
        opacity: 0.5;
    }
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: last baseline;
    gap: var(--CademyUIRichtextEditor-spacing-xxSmall);
    &:hover {
        .gutter {
            opacity: 1;
        }
    }
}

.gutter {
    opacity: 0;
    display: flex;
    .dragHandle {
        cursor: move;
    }
    .addButton {
        cursor: pointer;
        display: grid;
        align-items: center;
        border: none;
        background: var(--CademyUIRichtextEditor-color-body);
        border-radius: var(--CademyUIRichtextEditor-corners-rounded);
        color: var(--CademyUIRichtextEditor-color-onBody);
        padding: var(--CademyUIRichtextEditor-spacing-xxSmall);
        &:hover {
            background: color-mix(
                in srgb,
                var(--CademyUIRichtextEditor-color-body),
                var(--CademyUIRichtextEditor-color-onBody)
                    var(--CademyUIRichtextEditor-stateLayerOpacity-hover)
            );
        }
        &:active {
            background: color-mix(
                in srgb,
                var(--CademyUIRichtextEditor-color-body),
                var(--CademyUIRichtextEditor-color-onBody)
                    var(--CademyUIRichtextEditor-stateLayerOpacity-active)
            );
        }
        svg {
            width: 1rem;
            height: 1rem;
            fill: var(--CademyUIRichtextEditor-color-onBody);
        }
    }
}
