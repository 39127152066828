.loggedInAsCard {
    display: flex;
    justify-content: space-between;

    a:hover {
        text-decoration: underline;
    }

    margin-block-end: 0.5em;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}

.backButton {
    gap: var(--gap-list-smaller);
    font-size: var(--font-size);

    svg {
        width: 0.75em;
        height: 0.75em;
    }
}

.spinnerOut {
    width: 3rem;
    margin: 5rem auto;
    display: block;
}

.spinnerIn {
    display: block;
    stroke: var(--brand-color);
}
