.paragraph {
    margin-block: var(--CademyUIRichtextEditor-spacing-xSmall);
}

.paragraph:first-of-type {
    margin-block-start: 0;
}

.paragraph:last-of-type {
    margin-block-end: 0;
}
