.radioGroup {
    list-style-type: none;
    padding: 0;
    padding-block-start: var(--padding-small);
    margin: 0;
    .option {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        column-gap: var(--padding-small);
        margin-block: var(--padding-tiny);
        cursor: pointer;

        .radio {
            height: 1.5em;
            width: 1.5em;
            border: 1px solid var(--icon-fill-light);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            background-color: var(--white);
        }

        &:hover {
            .radio {
                background: var(--bg-2);
            }
        }

        &.selected {
            .radio {
                background: var(--white);
                border: 0.125em solid var(--blue);
            }
            .radioChecked {
                width: var(--padding-small);
                height: var(--padding-small);
                background: var(--blue);
                border-radius: 50%;
            }
        }

        &.partialSelected {
            .radio {
                background: var(--white);
                border: 0.125em solid var(--border);
            }
            .radioChecked {
                width: var(--padding-small);
                height: var(--padding-small);
                background: var(--border);
                border-radius: 50%;
            }
        }

        &.indent {
            margin-inline-start: var(--padding);
        }
    }
}
