.editLinkContainer {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    height: 1em;
}

.button {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: var(--CademyUIRichtextEditor-spacing-xSmall);
    border: none;
    background-color: transparent;
    font-size: var(--CademyUIRichtextEditor-typeset-bodyMediumFont);
    cursor: pointer;
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    width: 100%;

    &:hover {
        background-color: color-mix(
            in srgb,
            var(--CademyUIRichtextEditor-color-surface1),
            var(--CademyUIRichtextEditor-color-onSurface1)
                var(--CademyUIRichtextEditor-stateLayerOpacity-hover)
        );
    }
    > svg {
        height: 1em;
        width: 1em;
    }
}
