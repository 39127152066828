.comment {
    display: flex;
    flex-direction: row;
    gap: var(--padding);

    .avatar {
        display: flex;
    }

    .body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex: 1;
    }
}

@media screen and (max-width: 767px) {
    .comment {
        gap: var(--padding-smaller);
    }
}
