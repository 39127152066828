.pollOption {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    border: 1px solid var(--border);
    border-radius: var(--rounded-corners-pronounced);
    cursor: pointer;
    transition: background-size 200ms linear;
    background-size: 0% 100%;

    label {
        cursor: pointer;
        padding: calc(var(--padding-smaller) * 2);
    }

    &.withVotes {
        background-repeat: no-repeat;
        background-image: linear-gradient(90deg, var(--bg-grey-dark), var(--bg-grey-dark)),
            linear-gradient(90deg, var(--white), var(--white));
        background-size:
            var(--votePercentage) 100%,
            calc(100% - var(--votePercentage)) 100%;

        cursor: auto;

        label {
            width: 100%;
            display: flex;
            justify-content: space-between;

            background: inherit;
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
            filter: sepia(5) saturate(100) invert(1) grayscale(1) contrast(9);
            cursor: auto;

            span:first-of-type {
                max-width: calc(100% - 4em);
            }
        }

        .voteSection {
            display: flex;
            align-items: center;
            gap: var(--margin);
        }

        &.isMyVote {
            border: 1px solid var(--brand-color);
        }

        &.isHighlighted {
            background-image: linear-gradient(90deg, var(--brand-color), var(--brand-color)),
                linear-gradient(90deg, var(--white), var(--white));
        }

        .voteCount {
            color: var(--red);
        }

        &:hover {
            border: 1px solid var(--border);

            &.isMyVote {
                border: 1px solid var(--brand-color);
            }
        }
    }

    &:hover {
        border: 1px solid var(--brand-color);
    }
}

.myVote {
    position: absolute;
    right: 4em;

    height: var(--font-size);
    border-radius: 90%;
    border: 1px solid black;

    filter: unset;

    svg {
        height: var(--font-size);
        width: var(--font-size);

        fill: var(--brand-color);

        filter: drop-shadow(1px 1px 0px var(--on-brand-color))
            drop-shadow(-1px -1px 0px var(--on-brand-color))
            drop-shadow(1px -1px 0px var(--on-brand-color))
            drop-shadow(-1px 1px 0px var(--on-brand-color));
    }
}
