.cell {
    border: 1px solid var(--CademyUIRichtextEditor-color-border);
    padding: var(--CademyUIRichtextEditor-spacing-small);
    position: relative;
    vertical-align: top;
    &:hover {
        .columnButtonContainer,
        .rowButtonContainer {
            visibility: visible;
        }
    }

    [data-radix-popper-content-wrapper] {
        z-index: 2 !important;
    }
}

.columnButtonContainer {
    position: absolute;
    width: 100%;
    visibility: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    left: 0;
    top: calc(var(--CademyUIRichtextEditor-spacing-small) * -1);
    button {
        box-shadow: var(--CademyUIRichtextEditor-shadow-depth1);
    }
}

.rowButtonContainer {
    position: absolute;
    height: 100%;
    visibility: hidden;
    display: flex;
    align-items: center;
    top: 0;
    left: calc(var(--CademyUIRichtextEditor-spacing-small) * -1);
    button {
        box-shadow: var(--CademyUIRichtextEditor-shadow-depth1);
    }
}

.dropdown {
    background: var(--CademyUIRichtextEditor-color-surface1);
    border: 1px solid var(--CademyUIRichtextEditor-color-border);
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    display: grid;
    grid-template-columns: 1fr;
    padding: var(--CademyUIRichtextEditor-spacing-xxSmall);
    gap: var(--CademyUIRichtextEditor-spacing-xxSmall);
}

.dropdownItem {
    padding-inline: var(--CademyUIRichtextEditor-spacing-small);
    padding-block: var(--CademyUIRichtextEditor-spacing-xSmall);
    font: var(--CademyUIRichtextEditor-typeset-labelSmallFont);
    background: var(--CademyUIRichtextEditor-color-surface1);
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    outline: none;
    cursor: pointer;
    &:hover,
    &[data-highlighted] {
        background: color-mix(
            in srgb,
            var(--CademyUIRichtextEditor-color-surface1),
            var(--CademyUIRichtextEditor-color-onSurface1)
                var(--CademyUIRichtextEditor-stateLayerOpacity-hover)
        );
    }
    &:active {
        background: color-mix(
            in srgb,
            var(--CademyUIRichtextEditor-color-surface1),
            var(--CademyUIRichtextEditor-color-onSurface1)
                var(--CademyUIRichtextEditor-stateLayerOpacity-active)
        );
    }
}
