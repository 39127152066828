.deletePostButton {
    color: var(--red);
    fill: var(--red);
}

.dropdownContent {
    min-width: 150px;
    max-width: 320px;
    background-color: var(--white);
    border-radius: var(--border-radius);
    border: 1px solid var(--border);
    box-shadow: var(--card-box-shadow);
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    z-index: 11;
}

.dropdownContent[data-side='top'] {
    animation-name: slideDownAndFade;
}

.dropdownContent[data-side='right'] {
    animation-name: slideLeftAndFade;
}

.dropdownContent[data-side='bottom'] {
    animation-name: slideUpAndFade;
}

.dropdownContent[data-side='left'] {
    animation-name: slideRightAndFade;
}

.dropdownItem {
    display: flex;
    color: var(--text);
    outline: none;

    &:hover {
        background: var(--bg-2);
    }

    button {
        flex: 1;
        gap: var(--padding-smaller);
        display: flex;
        justify-content: flex-start;
    }

    button,
    button:hover {
        justify-content: flex-start;
        border: none;
        background: transparent;
    }
}

.separator {
    height: 1px;
    background-color: var(--border);
}

.trigger {
    color: var(--text-muted);
    fill: var(--text-muted);

    &:hover {
        border: 1px solid var(--border);
        cursor: pointer;
        color: var(--text);
        fill: var(--text);
    }

    &[data-state='open'],
    &[data-state='open']:hover {
        background: var(--bg-2);
        border-color: transparent;
        color: var(--text);
        fill: var(--text);
    }
}

@keyframes slideUpAndFade {
    from {
        opacity: 0;
        transform: translateY(2px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    from {
        opacity: 0;
        transform: translateX(-2px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    from {
        opacity: 0;
        transform: translateX(2px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}
