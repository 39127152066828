.list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: var(--gap-list);

    .item {
        display: flex;
        gap: var(--gap-list);
        align-items: center;
        font-size: var(--font-size-smaller);
        font-weight: var(--font-weight-bolder);

        svg {
            width: 0.9rem;
            fill: var(--cademy-brand-color);
        }
    }
}
