.features {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    justify-items: flex-start;
}

.feature_button {
    background-color: transparent;
    border: 1px solid transparent;
}

.badge {
    background-color: var(--yellow100);
    line-height: 1rem;
    padding: 6px 8px;
    border-radius: 5px;
}

.description {
    color: var(--text);
    margin: 0;
}

.btn_contents {
    display: flex;
    align-items: center;
    gap: 10px;

    .icon {
        background-color: var(--blue50);
        width: 2rem;
        height: 2rem;
        border-radius: var(--rounded-corners-pill);

        svg {
            fill: var(--cademy-brand-color);
        }
    }
}

@media (max-width: 896px) {
    .features {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 668px) {
    .features {
        grid-template-columns: 1fr;
    }
}
