.terms {
    margin-block: var(--margin-bigger);
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > label:first-of-type {
        flex: unset;
    }

    > div:last-of-type {
        flex-basis: 100%;
        padding-inline-start: 2em;
    }
}

.hidden {
    display: none;
}

.required {
    color: var(--red);
}
