.container {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
    border: transparent;
    padding-inline: 0;
}

.checkbox {
    margin-block-end: var(--padding-small);
}
