.closeButton {
    position: absolute;
    display: block;
    border: none;
    outline: none;
    right: 0;
    margin-block-start: var(--padding-small);

    svg {
        fill: var(--black);
        width: 16px;
        height: 16px;
    }
}

.closeButton:hover {
    outline: none;
    border: none;
}

.modal {
    position: relative;
}
