.divider {
    background-color: var(--CademyUIRichtextEditor-color-border);
    border-style: none;
    cursor: pointer;
    border-radius: var(--CademyUIRichtextEditor-corners-pill);
    height: 0.125rem;
}

.selectedAndFocused {
    --ring-offset-width: 2px;
    --ring-color: var(--CademyUIRichtextEditor-color-primary);
    --ring-offset-color: rgba(255, 255, 255, 1);
    --ring-offset-shadow: 0 0 0 var(--ring-offset-width) var(--ring-offset-color);
    --ring-shadow: 0 0 0 calc(2px + var(--ring-offset-width)) var(--ring-color);

    box-shadow:
        var(--ring-offset-shadow),
        var(--ring-shadow),
        0 0 rgba(0, 0, 0, 1);
}
