.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .button {
        padding: var(--padding-tiny);
        border-radius: var(--border-radius);
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;

        font-size: var(--font-size-smaller);
        color: var(--text-muted);
    }

    .button:hover {
        background-color: var(--bg-2);
    }
}
