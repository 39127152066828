.fixedToolbarButtons {
    overflow: hidden;
    width: 100%;
}

.fixedToolbarButtonsInner {
    display: flex;
    flex-wrap: wrap;
    transform: translateX(calc(-1px));
}
