.subtrigger {
    display: flex;
    padding-block: var(--CademyUIRichtextEditor-spacing-xxSmall);
    padding-inline: var(--CademyUIRichtextEditor-spacing-xSmall);
    align-items: center;
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    outline-style: none;
    font: var(--CademyUIRichtextEditor-typeset-labelSmallFont);
    cursor: default;
    user-select: none;
}

.subtrigger[data-disabled] {
    pointer-events: none;
    opacity: 50%;
}
