.inlineCombobox {
    position: relative;
    min-height: 1lh;

    .inputValue {
        overflow: hidden;
        text-wrap: nowrap;
        visibility: hidden;
    }

    .innerCombobox {
        position: absolute;
        top: 0;
        left: 0;

        outline: 2px solid transparent;
        outline-offset: 2px;

        background-color: transparent;
        height: 100%;
        width: 100%;
        border: none;

        font-family: inherit;
        font-family: inherit;
        font-feature-settings: inherit;
        font-variation-settings: inherit;
        font-size: 100%;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        color: inherit;
        margin: 0;
        padding: 0;
    }
}

.popover {
    z-index: 500;
    max-height: 288px;
    width: 300px;
    overflow-y: auto;
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    background-color: var(--CademyUIRichtextEditor-color-surface1);
    box-shadow: var(--CademyUIRichtextEditor-shadow-depth1);
}

.comboboxItemDefault {
    position: relative;
    display: flex;
    align-items: center;
    height: 2.25rem;
    padding: var(--CademyUIRichtextEditor-spacing-xSmall)
        var(--CademyUIRichtextEditor-spacing-small);
    font: var(--CademyUIRichtextEditor-typeset-labelSmallFont);
    outline: none;
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    svg {
        fill: var(--CademyUIRichtextEditor-color-onSurface1);
    }
}

.comboboxItemInteractive {
    @extend .comboboxItemDefault;

    cursor: pointer;
    &[data-active-item='true'] {
        background-color: color-mix(
            in srgb,
            var(--CademyUIRichtextEditor-color-surface1),
            var(--CademyUIRichtextEditor-color-onSurface1)
                var(--CademyUIRichtextEditor-stateLayerOpacity-active)
        );
    }

    &:hover {
        background-color: color-mix(
            in srgb,
            var(--CademyUIRichtextEditor-color-surface1),
            var(--CademyUIRichtextEditor-color-onSurface1)
                var(--CademyUIRichtextEditor-stateLayerOpacity-hover)
        );
    }
}

.comboboxItemEmpty {
    @extend .comboboxItemDefault;
}
