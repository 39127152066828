.selectContainer {
    min-height: 350px;
}

.footerButtons {
    display: flex;
    justify-content: space-between;
    button {
        svg {
            margin-right: var(--padding-tiny);
        }
    }
}

.alert {
    margin-block: var(--margin);
}
