.bookAsideContainer {
    margin-block-start: 1em;

    grid-area: bookAside;
    .bookAsideStickyContainer {
        position: sticky;
        top: 10px;
        display: flex;
        flex-direction: column;
        gap: var(--gap-list);
    }

    article {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 10px;
    }
}
