.buyerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.continueButtonContainer {
    display: flex;
    justify-content: flex-end;

    button {
        cursor: pointer;
    }
}

.errorAlert {
    margin-block-end: 1em;
}
