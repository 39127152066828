.label {
    color: var(--text);
    font-size: var(--font-size);
    display: inline-block;
    max-width: 100%;
}

.label[data-required-strategy='required'][data-required='true']::after {
    content: '*';
    font-size: var(--font-size);
    color: var(--red);
    margin-inline-start: 0.2em;
}

.label[data-required-strategy='optional'][data-required='false']::after {
    content: 'Optional';
    box-sizing: border-box;
    font-size: var(--font-size-tiny);
    background-color: rgba(0, 0, 0, 0.05);
    padding: 0.125em 0.3125em 0.125em 0.3125em;
    border-radius: var(--border-radius);
    position: absolute;
    margin-inline-start: var(--font-size);
}
