.toolbarButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: var(--CademyUIRichtextEditor-spacing-xSmall);
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    color: var(--CademyUIRichtextEditor-color-onBody);

    svg {
        width: 1em;
        height: 1em;
        fill: var(--CademyUIRichtextEditor-color-onBody);
    }
}

.toolbarButton:hover {
    background-color: color-mix(
        in srgb,
        var(--CademyUIRichtextEditor-color-body),
        var(--CademyUIRichtextEditor-color-onBody)
            var(--CademyUIRichtextEditor-stateLayerOpacity-hover)
    );
}

.toolbarToggleItem[aria-checked='true'] {
    color: var(--CademyUIRichtextEditor-color-primary);
    svg {
        fill: var(--CademyUIRichtextEditor-color-primary);
    }
}

.toolbarToggleItem_isDropdown {
    display: flex;
    padding-inline-end: var(--CademyUIRichtextEditor-spacing-xxSmall);
    margin-block-start: var(--CademyUIRichtextEditor-spacing-xxSmall);
    margin-block-end: var(--CademyUIRichtextEditor-spacing-xxSmall);
    justify-content: space-between;
}

.dropdownIcon {
    padding-inline-start: var(--CademyUIRichtextEditor-spacing-xSmall);
}

.toolbarButton_isDropdown {
    padding-inline-end: var(--CademyUIRichtextEditor-spacing-xxSmall);
}

.dropdownChildren {
    display: flex;
    flex-direction: row;
    align-items: center;
}
