.container {
    summary {
        &::marker,
        &::-webkit-details-marker {
            display: none;
        }
        list-style: none;

        display: grid;
        grid-template-columns: 1fr auto;
        gap: var(--padding);
        align-items: center;
        padding: var(--padding);

        cursor: pointer;

        .title {
            display: flex;
            align-items: center;
            column-gap: var(--padding-small);
            flex-wrap: wrap;
            h3 {
                margin: 0;
            }
            svg {
                circle {
                    stroke: var(--text-muted);
                }
            }
        }

        .expandIcons {
            svg {
                width: 1em;
            }
            .expandIcon {
                width: 1em;
                display: inherit;
            }
            .collapseIcon {
                width: 1em;
                display: none;
            }
        }
    }

    &[open] {
        summary {
            .expandIcon {
                display: none;
            }

            .collapseIcon {
                display: inherit;
            }
            border-bottom: 1px solid var(--border);
        }
    }

    .alerts {
        padding-block: var(--padding-small);
        padding-inline: var(--padding);
        margin: 0;
        [role='alert'] {
            margin: 0;
            display: block;
            max-width: initial;
        }
    }

    .content {
        padding: var(--padding);
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        column-gap: var(--padding);

        .detail {
            display: block;
        }

        .detailLabel {
            font-weight: var(--font-weight-bold);
        }

        .updateButtons {
            display: flex;
            gap: var(--padding-small);
            flex-flow: wrap;

            button {
                display: flex;
                gap: var(--padding-tiny);
            }
        }

        .transactions {
            width: 100%;
            border-collapse: collapse;
            thead {
                display: none;
            }
            tr {
                display: grid;
                grid-template-columns: 1fr auto auto;
                column-gap: var(--padding-tiny);
                border-bottom: 1px solid var(--border);
                padding-block: var(--padding-tiny);
            }
        }
    }
}
