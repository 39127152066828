.membershipRequiredContainer {
    background-color: #fdf4f4;
    border: 1px solid var(--red);
    border-radius: 5px;
    padding: 20px;

    display: grid;
    grid-template-columns: 1fr;
    gap: var(--gap-list-small);

    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-smaller);

    text-align: left;
    margin-block-end: 10px;

    & > p {
        margin: 0;
        text-align: center;
    }
}

.membershipRequired {
    background-color: #fdf4f4;
    border: 1px solid var(--red);
    border-radius: var(--border-radius);
    margin-block: var(--padding-small);
    padding: var(--padding);

    p:first-of-type {
        font-weight: var(--font-weight-bold);
    }

    p > a {
        font-weight: var(--font-weight-bold);
    }

    p {
        margin: 0;
        font-size: var(--font-size-smaller);
        font-weight: var(--font-weight-bolder);
    }
}

.membershipAccessMessage {
    position: relative;

    p {
        margin: 0;
        font-size: var(--font-size-smaller);
        font-weight: var(--font-weight-bolder);
    }
}
