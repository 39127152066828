.features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    justify-items: stretch;
}

.feature_button {
    background-color: transparent;
    border: 1px solid transparent;
}

.features_card {
    border: 1px solid var(--border);
    border-radius: var(--border-radius);
    padding: var(--padding);
    padding-block-end: var(--margin-bigger);

    .heading {
        text-align: center;
    }
}

.badge {
    background-color: var(--yellow100);
    line-height: 1rem;
    padding: 6px 8px;
    border-radius: 5px;
}

.description {
    color: var(--text);
    margin: 0;
}

.btn_contents {
    display: flex;
    align-items: center;
    gap: 10px;

    .icon {
        background-color: var(--blue50);
        width: 2rem;
        height: 2rem;
        border-radius: var(--rounded-corners-pill);

        svg {
            fill: var(--cademy-brand-color);
        }
    }
}

@media (max-width: 940px) {
    .features {
        grid-template-columns: 1fr;
    }
}
