.dropdownMenuItem {
    --_hoverBackground: color-mix(
        in srgb,
        var(--CademyUIRichtextEditor-color-surface1),
        var(--CademyUIRichtextEditor-color-onSurface1)
            var(--CademyUIRichtextEditor-stateLayerOpacity-hover)
    );

    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--CademyUIRichtextEditor-spacing-xxSmall)
        var(--CademyUIRichtextEditor-spacing-small);
    font: var(--CademyUIRichtextEditor-typeset-labelSmallFont);
    font-weight: 400;
    border-radius: inherit;
    outline: none;

    &[data-highlighted] {
        background-color: var(--_hoverBackground);
    }

    &:hover {
        background-color: var(--_hoverBackground);
    }

    svg {
        width: 1em;
        height: 1em;
    }
}
