.separator {
    flex-shrink: 0;
    background-color: var(--CademyUIRichtextEditor-color-border);
    margin: 0;
    &Vertical {
        height: 100%;
        width: 1px;
    }
    &Horizontal {
        height: 1px;
        width: 100%;
    }
}
