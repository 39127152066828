.fieldset {
    box-sizing: border-box;
    border: none;
    padding: 0;

    legend {
        padding-inline-start: 0;
        margin-block-end: var(--padding-tiny);
    }

    border: 1px solid var(--border);
    border-radius: var(--border-radius);
    padding: var(--padding-small);
    min-inline-size: 100%;
}
