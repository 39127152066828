.heading {
    h2 {
        margin: 0;
        font-size: var(--font-size-bigger);
    }
}

.costSection {
    padding: 0;
    margin: 0;
    list-style: none;
}

.totalSummary {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-bolder);
}

.preferredDatesList {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: var(--font-size-smaller);
    li {
        display: flex;
        align-items: center;
        gap: var(--padding-tiny);
    }
}
