.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1002;
}

.modal {
    width: 100vw;
    max-height: 90vh;
    max-height: 100dvh;
    background: var(--white);
    border-radius: var(--border-radius);
    overflow: auto;
    box-sizing: border-box;
    display: grid;
    grid-auto-rows: 1fr;
}

@media (min-width: 672px) {
    .modal.extra-small {
        width: 48vw;
        max-height: 48vh;
    }
    .modal.small {
        width: 60vw;
        max-height: 72vh;
    }
    .modal.medium {
        width: 84vw;
        max-height: 84vh;
    }
    .modal.large {
        width: 96vw;
        max-height: 96vh;
    }
}

@media (min-width: 1056px) {
    .modal.extra-small {
        width: 32vw;
    }
    .modal.small {
        width: 42vw;
    }
    .modal.medium {
        width: 60vw;
    }
    .modal.large {
        width: 84vw;
    }
}

@media (min-width: 1312px) {
    .modal.extra-small {
        width: 24vw;
    }
    .modal.small {
        width: 36vw;
    }
    .modal.medium {
        width: 48vw;
    }
    .modal.large {
        width: 72vw;
    }
}
