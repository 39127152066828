.toolbarGroup {
    display: flex;
    flex-direction: row;
}

.separatorWrapper {
    padding-block-start: var(--CademyUIRichtextEditor-spacing-xxSmall);
    height: 50%;
}

.children {
    display: flex;
    margin-inline-start: var(--CademyUIRichtextEditor-spacing-xxSmall);
    margin-inline-end: var(--CademyUIRichtextEditor-spacing-xxSmall);
    gap: var(--CademyUIRichtextEditor-spacing-xxSmall);
    align-items: center;
}
