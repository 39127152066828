.iframe {
    border: none;
    height: 100%;
    width: 100%;
    border-radius: var(--rounded-corners-standard);
}

.processing {
    position: relative;
    height: 100%;
    width: 100%;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        filter: grayscale(100%);
    }

    p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--on-brand-color);
        background-color: var(--brand-color);
        border-radius: var(--border-radius);
        padding: var(--padding-tiny);
    }
}
