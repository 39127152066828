.table {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    position: relative;
    margin-block: var(--CademyUIRichtextEditor-spacing-xSmall);
    width: 100%;
    &:hover {
        .rowButtonContainer,
        .columnButtonContainer {
            visibility: visible;
        }
    }
}

.rowButtonContainer {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    button {
        margin-top: var(--CademyUIRichtextEditor-spacing-xxSmall);
    }
}

.columnButtonContainer {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    display: flex;
    visibility: hidden;
    button {
        margin-left: var(--CademyUIRichtextEditor-spacing-xxSmall);
    }
}

.rowButtonContainer,
.columnButtonContainer {
    button {
        color: var(--CademyUIRichtextEditor-color-onSurface1);
        background: var(--CademyUIRichtextEditor-color-surface1);
        border: 1px solid var(--CademyUIRichtextEditor-color-border);
        border-radius: var(--CademyUIRichtextEditor-corners-rounded);
        padding: var(--CademyUIRichtextEditor-spacing-xxSmall);
        cursor: pointer;
        &:hover {
            background: color-mix(
                in srgb,
                var(--CademyUIRichtextEditor-color-surface1),
                var(--CademyUIRichtextEditor-color-onSurface1)
                    var(--CademyUIRichtextEditor-stateLayerOpacity-hover)
            );
        }
        &:active {
            background: color-mix(
                in srgb,
                var(--CademyUIRichtextEditor-color-surface1),
                var(--CademyUIRichtextEditor-color-onSurface1)
                    var(--CademyUIRichtextEditor-stateLayerOpacity-active)
            );
        }
        line-height: 0;
        svg {
            width: 0.7rem;
        }
    }
}
