.sharingSelectorContainer {
    display: flex;
    align-items: center;
    margin-bottom: 30;
}

.sharing_card {
    padding: 20px;
    cursor: pointer;
    border: 1px solid var(--border);
    margin-right: 10px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 2 1 80px;
    max-width: 80px;
    min-width: 30px;
}

.sharing_card_label {
    margin-top: 15px;
    font-size: 14px;
}

.sharing_card_input {
    display: none;
}

.sharing_card_icon {
    height: 20px;
}

.sharing_card_selected {
    border: none;
    box-shadow: inset 0 0 0 1px var(--blue);
    background-color: #f3f9ff;
}

.sharing_card:hover {
    background-color: #f3f9ff;
}

.sharingMethodDescription {
    display: inline-block;
    margin-top: 1em;
}
