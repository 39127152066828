.container {
    padding: var(--padding);
    border: 1px solid var(--border);
    border-radius: var(--rounded-corners-standard);
    box-shadow: var(--card-box-shadow);
}

.processingLabel {
    padding: var(--padding-tiny);
    border-radius: var(--border-radius);
    margin-block-end: var(--margin);
    color: var(--text);

    font-size: var(--font-size-smaller);
    font-weight: var(--font-weight-bold);

    display: flex;
    justify-content: space-between;
    align-items: center;

    .spinnerContainer {
        height: 1em;
        width: 1em;
    }
    .spinnerCircle {
        stroke: var(--brand-color);
    }
}

.postProcessing {
    background-color: var(--bg-grey);
    color: var(--text-muted);

    padding: var(--padding-smaller);
    border-radius: var(--border-radius);

    .container {
        background-color: var(--white);
    }
}

.divider {
    height: 1px;
    width: 100%;
    background-color: var(--border);
    border: none;
}

.commentsBlockEndDivider {
    margin-block-start: var(--padding);
}

.postHeader {
    display: flex;
    justify-content: space-between;

    .author {
        gap: var(--padding-small);
    }
}
