.toolBar {
    background-color: transparent;
}

.editor {
    --CademyUIRichtextEditor-color-body: var(--bg-1);
    --CademyUIRichtextEditor-color-border: transparent;
    border: none;
    outline: none;
    background-color: transparent;
    align-self: center;
    padding: 0;
    padding-block-start: var(--padding-tiny);
    padding-inline: var(--padding-smaller);
}

.CommentEditComposerContainer {
    display: flex;
    align-items: flex-start;
    > *:first-child {
        flex: 1;
    }
    > * {
        flex-shrink: 0;
    }
    border: 1px solid var(--bg-2);
    border-radius: var(--rounded-corners-pill);
    background-color: var(--bg-2);
    padding-block: 0;
    padding-inline: var(--padding-smaller);
    &:hover {
        outline: 1px solid var(--blue200);
        cursor: text;
    }
    &:focus-within {
        outline: 1px solid var(--blue);
    }
}

.buttonRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 0.75em;
    gap: var(--gap-list-small);
    font-size: var(--font-size-extra-small);

    > button {
        height: 1.75rem;
    }
}
