.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-list-smaller);
}
.heading {
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-smaller);
    color: var(--text);
    text-wrap: balance;
}

.icons {
    display: flex;
    gap: var(--gap-list-smaller);
    align-items: center;
}
