.container {
    display: flex;
    flex-direction: column;
    gap: var(--padding);
}

.contents {
    display: flex;
    flex-direction: column;
    margin-block: var(--margin-big);

    & p {
        margin: 0;
    }
}
