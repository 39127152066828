.composer {
    padding-block-start: var(--padding-small);
    padding-block-end: var(--padding-tiny);
    display: flex;
    gap: var(--padding-small);
    align-items: flex-start;
}

.avatar {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    > div,
    > a > div {
        border-radius: 100%;
    }
}

.composerContent {
    flex-grow: 1;
}
