.floatingToolbar {
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    border: 1px solid var(--CademyUIRichtextEditor-color-border);
    background-color: var(--CademyUIRichtextEditor-color-body);
    box-shadow: var(--CademyUIRichtextEditor-shadow-depth2);
    color: var(--CademyUIRichtextEditor-color-onBody);
    z-index: 1;

    svg {
        fill: var(--CademyUIRichtextEditor-color-onBody);
    }
}
