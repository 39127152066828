.toolBar {
    background-color: transparent;
}

.editor {
    --CademyUIRichtextEditor-color-body: var(--bg-2);
    --CademyUIRichtextEditor-color-border: transparent;
    border: none;
    outline: none;
    background-color: transparent;
    align-self: center;
    padding: 0;
    padding-block-start: var(--padding-tiny);
    padding-inline: var(--padding-smaller);
}

.CommentComposerContainer {
    display: flex;
    align-items: flex-start;
    > *:first-child {
        flex: 1;
    }
    > * {
        flex-shrink: 0;
    }
    border: 1px solid var(--bg-2);
    border-radius: var(--rounded-corners-pill);
    background-color: var(--bg-2);
    padding-block: 2px;
    padding-inline: var(--padding-smaller);
    &:hover {
        outline: 1px solid var(--blue200);
        cursor: text;
    }
    &:focus-within {
        outline: 1px solid var(--blue);
    }
}

.submitButton {
    padding-inline: var(--padding-small);
    height: 2.5em;
    border: none;
    border-radius: var(--rounded-corners-pill);
    cursor: pointer;
    &Icon {
        width: 1em;
        height: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline-start: -1px;
    }
    &:focus {
        border: none;
        outline: none;
    }
    &:hover {
        border: none;
        opacity: 0.9;
        transition: all 0.2s ease 0s;
    }
    &:disabled {
        background-color: transparent;
        color: var(--text-muted);
        fill: var(--text-muted);
        border: none;

        > svg {
            fill: var(--text-muted);
        }
        &:hover {
            cursor: not-allowed;
            border: none;
        }
        &:hover {
            background-color: transparent;
            color: var(--text-muted);
            fill: var(--text-muted);
            box-shadow: none;
        }
    }
}
