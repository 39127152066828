.parentContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.container {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    align-items: stretch;

    .childContainer {
        flex-grow: 2;
    }
}

.resizeHandle {
    cursor: ew-resize;
    padding-inline: var(--CademyUIRichtextEditor-spacing-xSmall);
    display: flex;
    align-items: center;

    span {
        display: block;
        width: 2px;
        height: 50px;
        background-color: var(--CademyUIRichtextEditor-color-primary);
        border-radius: var(--CademyUIRichtextEditor-corners-pill);
    }
}
