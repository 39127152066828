.faq {
    border-block-end: 1px solid var(--border);
}

.faq_question {
    border: unset;
    color: var(--text);
    background-color: unset;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    padding: 20px 0;
    width: 100%;
    text-align: start;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    svg {
        flex: 0 0 24px;
    }
}

.faq_question:hover {
    cursor: pointer;
    color: var(--cademy-blue-dark);
}

.faq_answer {
    margin-block-start: -20px;
}
