.popoverContent {
    padding: 1em;
    margin: var(--margin);
    z-index: 1005;
    display: flex;
    flex-direction: column;
}

.reactions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--margin-small);
    margin-block-start: var(--margin);
    margin-block-end: var(--margin-big);

    .reactionButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        gap: var(--margin-small);

        height: 3em;

        padding-block: var(--padding-tiny);
        padding-inline: var(--padding-small);

        align-items: center;
        border: 0.5px solid var(--border);
        border-radius: 20px;
        background-color: var(--bg-2);
        color: var(--text-muted);
        font-size: var(--font-size-smaller);
        cursor: pointer;
        transition: background-color 0.2s;

        &:not(.reactionButtonChosen):hover {
            border: 0.5px solid var(--blue200);
        }

        &.reactionButtonChosen {
            border: 0.5px solid var(--blue);
            background-color: var(--light-blue);
            color: var(--blue);
        }

        &.addReactionButton {
            width: 3em;
            height: 3em;
            svg {
                margin-inline-start: var(--margin-smaller);
                height: 16px;
                width: 16px;
            }
        }
    }
}

.tooltipUser {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    gap: var(--margin-small);
}
