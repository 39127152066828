.option {
    display: grid;
    grid-template-columns: auto 1fr;
    align-content: center;
    gap: var(--CademyUIRichtextEditor-spacing-medium);
    padding: var(--CademyUIRichtextEditor-spacing-xxSmall);
    height: auto;

    .icon {
        display: grid;
        align-content: center;
        justify-content: center;
        border: 1px solid var(--CademyUIRichtextEditor-color-border);
        height: 2.5rem;
        aspect-ratio: 1 / 1;
        padding: var(--CademyUIRichtextEditor-spacing-xSmall);
        border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    }

    .optionText {
        display: flex;
        flex-direction: column;
    }

    .optionDescription {
        opacity: 0.8;
    }
}
