.box {
    --_backgroundColor: var(--backgroundColor, var(--white));
    --_borderColor: var(--borderColor, var(--border));
    border-radius: var(--rounded-corners-standard);
    padding: var(--padding);
    background: var(--_backgroundColor);
    border: 1px solid var(--_borderColor);
}

.tinted {
    --backgroundColor: var(--bg-1);
}

.darker {
    --backgroundColor: var(--bg-3);
}
