.checkboxContainer {
    --_checkboxHeight: 1.5em;
    display: flex;
    flex: 1;
    align-items: center;
    height: var(--_checkboxHeight);
    position: relative;
    padding-inline-start: calc(var(--_checkboxHeight) + var(--padding-small));
    cursor: pointer;
    input ~ .checkbox {
        background-color: var(--white);
    }
    input:focus ~ .checkbox {
        outline: -webkit-focus-ring-color auto 1px;
    }
    input:hover ~ .checkbox {
        background: var(--bg-2);
    }
    input:checked ~ .checkbox {
        background-color: var(--blue);
        &:after {
            content: '';
            position: absolute;
            left: 0.48em;
            top: 0.13em;
            width: 0.3em;
            height: 0.8em;
            border: solid white;
            border-width: 0 0.2em 0.2em 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.checkboxContainer[data-required-strategy='required'][data-required='true']::after {
    content: '*';
    font-size: var(--font-size);
    color: var(--red);
    margin-inline-start: 0.2em;
}

.checkboxContainer[data-required-strategy='optional'][data-required='false']::after {
    content: 'Optional';
    box-sizing: border-box;
    font-size: var(--font-size-tiny);
    background-color: rgba(0, 0, 0, 0.05);
    padding: 0.125em 0.3125em 0.125em 0.3125em;
    border-radius: var(--border-radius);
    margin-inline-start: var(--font-size);
}

.checkbox {
    position: absolute;
    left: 0;
    height: var(--_checkboxHeight, 1.5em);
    width: var(--_checkboxHeight, 1.5em);
    border: 1px solid var(--icon-fill-light);
    border-radius: var(--border-radius);
}
