.contactAndMoreBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: var(--gap-list-small);
    width: 100%;
    margin-block-start: var(--gap-list);

    &:hover {
        background-color: var(--bg-2);
    }
}

.popover {
    padding: 0;

    > button {
        visibility: hidden;
    }
}

.popoverContents {
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-block: var(--padding-small);
        padding-inline: 3.125em;
        text-align: center;
        border-bottom: 1px solid var(--border);
        color: var(--text);
        font-size: var(--font-size-smaller);
        font-weight: var(--font-weight-bolder);

        &:hover {
            cursor: pointer;
            background-color: var(--bg-2);
        }
    }
}
