.heading {
    display: flex;
    gap: var(--padding-small);
    align-items: center;
    button[aria-expanded='true'] {
        background: var(--bg-grey-dark);
    }
    h1 {
        flex-grow: 2;
        margin: 0;
    }
    padding-block: var(--padding-small);
}

.subHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: var(--padding-small);
    gap: var(--padding-small);
    .subHeadingNav {
        display: flex;
        gap: var(--padding-small);
    }
}
