.textAreaContainer {
    --label-offset: calc(var(--font-size-extra-small) + 1em);

    display: grid;
    position: relative;
    box-sizing: border-box;

    /**
     * Faux textarea is used to avoid the text content overflowing into the label.
     */
    .fauxTextArea {
        border: 1px solid var(--border);
        border-radius: var(--border-radius);
        padding: 1px;
        padding-block-start: var(--label-offset);
        padding-block-end: 2px;
        width: 100%;
        overflow: hidden;
        background-color: var(--white);

        &:focus-within {
            box-shadow:
                inset 0 0 2px rgb(67 90 111 / 14%),
                inset 0 0 0 1px #579ad9,
                0 0 0 3px rgb(16 112 202 / 14%);
            padding-block-start: var(--label-offset);
        }
    }

    /**
     * Nest the scrollable element to avoid the content of the scrollable 
     * overflowing into the fauxTextArea border.
     */
    .fauxTextAreaInner {
        resize: vertical;
        overflow: auto;
    }

    textarea {
        background-color: inherit;
        box-sizing: border-box;
        color: var(--text-alt);
        font-family: var(--font-family);
        font-size: var(--font-size);
        font-weight: var(--font-weight-normal);
        line-height: var(--font-size);
        width: 100%;
        height: 100%;
        max-width: 100%;
        border: none;
        outline: none;
        padding: 0 var(--padding) 0 var(--padding);
        resize: none;
        overflow: hidden;
    }

    /**
     * For the dynamic label to work it requires a placeholder to be set on all fields
     * so we can target textareas with a value. The placeholder will never be shown.
     */
    textarea::placeholder {
        color: transparent;
    }

    label {
        position: absolute;
        top: 0;
        left: var(--padding);
        padding-block-start: 1em;
        line-height: var(--font-size);
        font-size: var(--font-size);
        transition: all 0.15s ease 0s;
        background-color: inherit;
        color: rgba(0, 0, 0, 0.8);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
    }

    textarea:focus ~ label,
    textarea:not(:placeholder-shown) ~ label {
        // Stops the scrollable content from directly touching the label text.
        padding-block-end: 3px;
        color: rgba(0, 0, 0, 0.8);
        font-size: var(--font-size-extra-small);
        left: var(--padding);
    }
}
