.select {
    .control {
        &_focused {
            border: 1px;
            box-shadow:
                inset 0 0 2px rgb(67 90 111 / 14%),
                inset 0 0 0 1px #579ad9,
                0 0 0 3px rgb(16 112 202 / 14%);
        }
    }
}
