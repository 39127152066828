.defaultTheme {
    --_fontFamily: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
        Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

    --CademyUIRichtextEditor-color-border: var(--border, #e6e8f0);
    --CademyUIRichtextEditor-color-border-muted: var(--border-muted, #edf0f2);
    --CademyUIRichtextEditor-color-body: white;
    --CademyUIRichtextEditor-color-onBody: var(--text, black);

    --CademyUIRichtextEditor-color-surface1: var(--bg-1, #fafbff);
    --CademyUIRichtextEditor-color-onSurface1: var(--text, black);
    --CademyUIRichtextEditor-color-surface2: black;
    --CademyUIRichtextEditor-color-onSurface2: white;

    --CademyUIRichtextEditor-color-primary: var(--cademy-blue, #3d56e0);
    --CademyUIRichtextEditor-color-onPrimary: white;
    --CademyUIRichtextEditor-color-codeBlock: var(--bg-2);
    --CademyUIRichtextEditor-color-onCodeBlock: var(--red);

    --CademyUIRichtextEditor-spacing-xxSmall: 0.25rem;
    --CademyUIRichtextEditor-spacing-xSmall: 0.5rem;
    --CademyUIRichtextEditor-spacing-small: 0.75rem;
    --CademyUIRichtextEditor-spacing-medium: 1rem;
    --CademyUIRichtextEditor-spacing-large: 1.25rem;
    --CademyUIRichtextEditor-spacing-xLarge: 1.75rem;
    --CademyUIRichtextEditor-spacing-xxLarge: 2.5rem;

    --CademyUIRichtextEditor-typeset-titleSmallFont: var(--font-weight-bolder, 500) 1.2rem / 1.7rem
        var(--font-family, var(--_fontFamily));
    --CademyUIRichtextEditor-typeset-titleMediumFont: var(--font-weight-bolder, 500) 1.35rem / 2rem
        var(--font-family, var(--_fontFamily));
    --CademyUIRichtextEditor-typeset-titleLargeFont: var(--font-weight-bolder, 500) 1.8rem / 2.35rem
        var(--font-family, var(--_fontFamily));

    --CademyUIRichtextEditor-typeset-bodySmallFont: var(--font-weight-normal, 400) 0.875rem / 1.1rem
        var(--font-family, var(--_fontFamily));
    --CademyUIRichtextEditor-typeset-bodyMediumFont: var(--font-weight-normal, 400) 1rem / 1.3rem
        var(--font-family, var(--_fontFamily));
    --CademyUIRichtextEditor-typeset-bodyLargeFont: var(--font-weight-normal, 400) 1.3rem / 2rem
        var(--font-family, var(--_fontFamily));

    --CademyUIRichtextEditor-typeset-labelSmallFont: var(--font-weight-bolder, 500) 0.9rem / 1.2rem
        var(--font-family, var(--_fontFamily));
    --CademyUIRichtextEditor-typeset-labelMediumFont: var(--font-weight-bolder, 500) 1rem / 1.3rem
        var(--font-family, var(--_fontFamily));
    --CademyUIRichtextEditor-typeset-labelLargeFont: var(--font-weight-bolder, 500) 1.15rem / 1.5rem
        var(--font-family, var(--_fontFamily));

    --CademyUIRichtextEditor-corners-rounded: var(--rounded-corners-standard, 5px);
    --CademyUIRichtextEditor-corners-pill: var(--rounded-corners-pill, 25px);

    --CademyUIRichtextEditor-shadow-depth1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --CademyUIRichtextEditor-shadow-depth2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    --CademyUIRichtextEditor-stateLayerOpacity-hover: 8%;
    --CademyUIRichtextEditor-stateLayerOpacity-active: 10%;
}
