.priceLabel {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-block-end: var(--padding-small);

    &Text {
        display: block;
        font-weight: var(--font-weight-bolder);
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: var(--text);
    }
}
