.attendeesContainer {
    display: grid;
    margin-block: var(--margin-bigger);
    gap: var(--margin);
}

.attendeeForm {
    h2 {
        margin-block-start: 0;
    }
}

.requiredHeading::after {
    content: '*';
    color: var(--red);
    margin-inline-start: 2px;
}

.bookerFormContainer {
    display: flex;
    flex-direction: column;
    gap: var(--margin);
    margin: 0;
    padding: 0;
    border: none;
    min-inline-size: auto;
}

.loggedInAsCard {
    display: flex;
    justify-content: space-between;

    a:hover {
        text-decoration: underline;
    }
}

.spinnerOut {
    width: 3rem;
    margin: 10rem auto;
    display: block;
}

.spinnerIn {
    display: block;
    stroke: var(--brand-color);
}

@media (max-width: 768px) {
    .loggedInAsCard {
        flex-direction: column;
    }
}
