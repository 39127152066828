.container {
    display: grid;
    overflow: hidden;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: var(--padding-small);
    padding-inline: var(--padding);
    border-block-end: 1px solid var(--border);
    h1 {
        margin: 0;
        text-align: center;
        padding-inline-end: 2.8em;
        font-size: var(--font-size-h3);
        font-weight: var(--font-weight-bold);
    }
    button {
        border: none;
        background: none;
        padding: 0;
        overflow: visible;
        width: 2.8em;
        height: 2.8em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        &:hover {
            background: var(--bg-2);
        }
        svg {
            width: 1.4em;
            height: 1.4em;
        }
    }
}

.contents {
    overflow: auto;
    padding: var(--padding);
}

.footer {
    padding: var(--padding);
    border-block-start: 1px solid var(--border);
}
