.fixedToolbarButtons {
    overflow: hidden;
}

.fixedToolbarButtonsInner {
    display: flex;
    flex-wrap: wrap;
    transform: translateX(calc(-1px));

    button {
        display: flex;
        margin-block-start: 2px;
        border-radius: var(--rounded-corners-pill);
    }
}
