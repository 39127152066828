.floatingToolbarButtons {
    overflow: hidden;
    width: 100%;
}

.floatingToolbarButtonsInner {
    display: flex;
    flex-wrap: wrap;
    transform: translateX(calc(-1px));
}
