.codeMark {
    font-family: monospace;
    font-size: 0.875rem;
    line-height: 1.5;
    tab-size: 2;
    background-color: var(--CademyUIRichtextEditor-color-codeBlock);
    border-radius: 0.375rem;
    padding: 0.25rem 0.5rem;
    color: var(--CademyUIRichtextEditor-color-onCodeBlock);
}
