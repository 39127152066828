.popover {
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    border: 1px solid var(--CademyUIRichtextEditor-color-border);
    background-color: var(--CademyUIRichtextEditor-color-body);
    padding: 0.5rem;
    box-shadow:
        0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    outline: none;
}
