.menuContent {
    max-height: 288px;
    width: 100%;
    max-width: 300px;
    overflow-y: auto;
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    background-color: var(--CademyUIRichtextEditor-color-surface1);
    color: var(--CademyUIRichtextEditor-color-onSurface1);
    box-shadow: var(--CademyUIRichtextEditor-shadow-depth1);

    svg {
        fill: var(--CademyUIRichtextEditor-color-onSurface1);
    }
}
