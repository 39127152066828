.DialogOverlay {
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.3);
}

.DialogContent {
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow:
        hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 800px;
    max-height: 85vh;
    padding: 25px;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    display: flex;
    flex-direction: column;
}
.DialogContent:focus {
    outline: none;
}

.DialogTitle {
    margin: 0;
    font-size: var(--font-size-h1);
}

.DialogDescription {
    margin: 10px 0 20px;
    color: var(--mauve-11);
    font-size: 15px;
    line-height: 1.5;
}

.Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    height: 35px;
}
.Button.violet {
    background-color: white;
    color: var(--violet-11);
    box-shadow: 0 2px 10px var(--black-a7);
}
.Button.violet:hover {
    background-color: var(--mauve-3);
}
.Button.violet:focus {
    box-shadow: 0 0 0 2px black;
}
.Button.green {
    background-color: var(--green-4);
    color: var(--green-11);
}
.Button.green:hover {
    background-color: var(--green-5);
}
.Button.green:focus {
    box-shadow: 0 0 0 2px var(--green-7);
}

.IconButton {
    font-family: inherit;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--violet-11);
    position: absolute;
    top: 10px;
    right: 10px;
}
.IconButton:hover {
    background-color: var(--violet-4);
}
.IconButton:focus {
    box-shadow: 0 0 0 2px var(--violet-7);
}

.Fieldset {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 15px;
}

.Label {
    font-size: 15px;
    color: var(--violet-11);
    width: 90px;
    text-align: right;
}

.Input {
    width: 100%;
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 15px;
    line-height: 1;
    color: var(--violet-11);
    box-shadow: 0 0 0 1px var(--violet-7);
    height: 35px;
}
.Input:focus {
    box-shadow: 0 0 0 2px var(--violet-8);
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes contentShow {
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

.termsScroller {
    overflow: auto;
}
