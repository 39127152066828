.editor {
    font: var(--CademyUIRichtextEditor-typeset-bodyMediumFont);
    padding: var(--CademyUIRichtextEditor-spacing-large);
    border: 1px solid var(--CademyUIRichtextEditor-color-border);
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    background: var(--CademyUIRichtextEditor-color-body);
    color: var(--CademyUIRichtextEditor-color-onBody);

    display: flex;
    flex-direction: column;
    align-items: end;

    &:focus-within,
    &:focus,
    &:active {
        border-color: var(--blue);
        outline: none;
    }
}
