.PopoverContent {
    border-radius: 4px;
    padding: 20px;
    max-width: min(260px, 75vw);
    background-color: white;
    box-shadow:
        hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    margin-left: 20px;
    margin-right: 20px;
}

.PopoverContent[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
}

.PopoverContent[data-state='open'][data-side='right'] {
    animation-name: slideLeftAndFade;
}

.PopoverContent[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
}

.PopoverContent[data-state='open'][data-side='left'] {
    animation-name: slideRightAndFade;
}

.PopoverArrow {
    fill: white;
}

.PopoverClose {
    background-color: var(--white);
    border: 1px solid transparent;
    font-family: inherit;
    border-radius: 4px;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -10px;

    svg {
        flex: 0 0 12px;
    }
}
.PopoverClose:hover {
    cursor: pointer;
    background-color: var(--bg-3);
}
.PopoverClose:focus {
    box-shadow: 0 0 0 2px var(--bg-2);
}

@keyframes slideUpAndFade {
    from {
        opacity: 0;
        transform: translateY(2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    from {
        opacity: 0;
        transform: translateX(-2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    from {
        opacity: 0;
        transform: translateX(2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
