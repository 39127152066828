.createLinkContainer {
    display: flex;
    flex-direction: column;
    width: 20rem;

    &Row {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        height: 1.25rem;

        .icon {
            width: 1.25rem;
            fill: var(--CademyUIRichtextEditor-color-onSurface1);
            opacity: 0.3;
            margin: 0.25rem;

            > svg {
                height: 100%;
                width: 100%;
            }
        }
    }
}

.inputField {
    display: flex;
    width: 100%;
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    background-color: transparent;
    font-size: var(--CademyUIRichtextEditor-typeset-labelSmallFont);
    border: none;
    padding: 0.25rem;
    width: 100%;

    &::placeholder {
        color: var(--CademyUIRichtextEditor-color-onSurface1);
        opacity: 0.3;
    }

    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 2px transparent;
    }
}
