.replies {
    margin-block-start: var(--padding-smaller);
}

.actions {
    .reply {
        margin-block-end: var(--padding-smaller);
        font-weight: var(--font-weight-bolder);
        font-size: var(--font-size);
    }
}

.replyComposerContainer {
    background-color: var(--white);
    border-radius: var(--rounded-corners-pronounced);
    margin-block-end: var(--padding-smaller);

    .replyComposer {
        background-color: transparent;
        border-radius: var(--rounded-corners-pronounced);
    }

    .toolBar {
        background-color: var(--white);
    }
}

.commentHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .options {
        visibility: hidden;
    }
}

.comment:hover,
.comment:focus,
.comment:focus-within,
.comment:has(button[data-state='open']) {
    .options {
        visibility: visible;
    }
}

.commentText {
    margin-block-end: var(--margin);
}

@media screen and (max-width: 767px) {
    .avatar {
        height: 24px;
    }

    .commentHeader {
        margin-block-end: var(--padding-tiny);
    }
}

@media (pointer: coarse) {
    .comment {
        .options {
            visibility: visible;
        }
    }
}
