.image {
    border: 1px solid transparent;
    width: 100%;
    min-width: 100%;

    &.selected {
        border: 1px solid var(--CademyUIRichtextEditor-color-primary);
    }
}

.emptyImage {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed var(--CademyUIRichtextEditor-color-border);
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);

    &.selected {
        border: 1px dashed var(--CademyUIRichtextEditor-color-primary);
    }

    button {
        padding: var(--CademyUIRichtextEditor-spacing-xSmall);
        cursor: pointer;
        background-color: var(--CademyUIRichtextEditor-color-surface1);
        border: 1px solid var(--CademyUIRichtextEditor-color-border);
        border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    }
}

.loading {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        border: 5px solid var(--CademyUIRichtextEditor-color-surface1);
        border-radius: 50%;
        border-top: 5px solid var(--CademyUIRichtextEditor-color-primary);
        width: 100px;
        height: 100px;
        animation: spinner 3s linear infinite;
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.floatingContainer {
    button {
        padding: var(--CademyUIRichtextEditor-spacing-xSmall);
        cursor: pointer;
        background-color: var(--CademyUIRichtextEditor-color-surface1);
        border: 1px solid var(--CademyUIRichtextEditor-color-border);
        border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    }
}
