.commentHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .options {
        visibility: hidden;
    }
}

.comment:hover,
.comment:focus,
.comment:focus-within,
.comment:has(button[data-state='open']) {
    .options {
        visibility: visible;
    }
}

.commentText {
    margin-block-end: var(--margin);
}

@media screen and (max-width: 767px) {
    .avatar {
        height: 24px;
    }

    .commentHeader {
        margin-block-end: var(--padding-tiny);
    }
}

@media (pointer: coarse) {
    .comment {
        .options {
            visibility: visible;
        }
    }
}
