.amount_card {
    padding: 10px 18px 10px 12px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    box-shadow:
        inset 0 0 0 1px rgba(67, 90, 111, 0.3),
        inset 0 1px 2px rgba(67, 90, 111, 0.14);

    &:hover {
        background-color: #f3f9ff;
    }
}

.amount_card_selected {
    border: none;
    box-shadow: inset 0 0 0 1px var(--blue);
    background-color: #f3f9ff;
}
