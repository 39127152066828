.container {
    margin-block-start: var(--margin-bigger);
    margin-block-end: var(--margin-giant);
    label {
        display: block;
        margin-block-end: var(--margin);
    }
}

.select {
    display: flex;

    li:first-of-type {
        button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            &:focus {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                outline: 1px solid var(--brand-color);
            }
        }
    }

    li:last-of-type {
        button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            &:focus {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                outline: 1px solid var(--brand-color);
            }
        }
    }
}
