.dialog {
    background-color: var(--black);
    border-radius: 0;

    .item {
        justify-content: center;
        position: relative;
        display: flex;

        img {
            object-fit: contain;
            margin: auto;
        }

        &:has(img) {
            align-items: center;
        }
    }

    .modalInner {
        min-height: 100%;
        position: relative;
    }

    .carouselContainer {
        height: 100%;
    }

    .previousButton {
        left: var(--padding-small);
    }
    .nextButton {
        right: var(--padding-small);
    }

    .attachment {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    .close {
        right: var(--padding-small);
        top: var(--padding-small);
        width: 2.25rem;
        height: 2.25rem;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        position: absolute;
        padding: var(--padding-tiny);
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid var(--text);

        &:hover {
            background: var(--brand-color);
            color: var(--on-brand-color);
            fill: var(--on-brand-color);
            border-color: var(--on-brand-color);
        }
    }
}

div[role='dialog'].dialog {
    width: 100dvw;
    height: 100dvh;
    max-width: none;
    max-height: none;
}
