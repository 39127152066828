.dropdownTrigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--CademyUIRichtextEditor-color-onBody);

    .triggerIcon {
        margin-inline-end: var(--CademyUIRichtextEditor-spacing-xxSmall);
    }
}

.dropdownMenuItem {
    --_activeBackground: color-mix(
        in srgb,
        var(--CademyUIRichtextEditor-color-surface1),
        var(--CademyUIRichtextEditor-color-onSurface1)
            var(--CademyUIRichtextEditor-stateLayerOpacity-hover)
    );

    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--CademyUIRichtextEditor-spacing-xxSmall)
        var(--CademyUIRichtextEditor-spacing-small);
    font: var(--CademyUIRichtextEditor-typeset-labelSmallFont);
    border-radius: inherit;
    outline: none;
    color: var(--CademyUIRichtextEditor-color-onBody);

    &[data-highlighted] {
        background-color: var(--_activeBackground);
    }

    &:hover {
        background-color: var(--_activeBackground);
    }

    .icon {
        margin-inline-end: var(--CademyUIRichtextEditor-spacing-xSmall);
        width: 1em;
        height: 1em;
        fill: var(--CademyUIRichtextEditor-color-onBody);
    }
}
