.poll {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--margin);

    .pollOptions {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--margin);

        &.isLoading {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    .pollFooter {
        display: flex;
        justify-content: space-between;
        color: var(--text-muted);
        font-size: var(--font-size-extra-small);
        padding-inline: var(--padding-smaller);

        > ul {
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: space-between;
            padding: 0;

            ul {
                --gap: var(--padding-smaller);
                display: flex;
                flex-direction: row;
                gap: var(--gap);
                padding-inline: 0;
                list-style: none;

                li::before {
                    content: '•';
                    padding-inline-end: var(--gap);
                }

                li:first-of-type::before {
                    content: none;
                }
            }
        }
    }
}
