.button {
    user-select: none;
    cursor: pointer;
    display: grid;
    align-items: center;
    border: none;
    background: var(--CademyUIRichtextEditor-color-body);
    border-radius: var(--CademyUIRichtextEditor-corners-rounded);
    color: var(--CademyUIRichtextEditor-color-onBody);
    padding: var(--CademyUIRichtextEditor-spacing-xxSmall);
    &:hover {
        background: color-mix(
            in srgb,
            var(--CademyUIRichtextEditor-color-body),
            var(--CademyUIRichtextEditor-color-onBody)
                var(--CademyUIRichtextEditor-stateLayerOpacity-hover)
        );
    }
    &:active {
        background: color-mix(
            in srgb,
            var(--CademyUIRichtextEditor-color-body),
            var(--CademyUIRichtextEditor-color-onBody)
                var(--CademyUIRichtextEditor-stateLayerOpacity-active)
        );
    }
    svg {
        width: 1rem;
        height: 1rem;
        fill: var(--CademyUIRichtextEditor-color-onBody);
    }
}
